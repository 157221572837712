import React from "react";
import { Route, Routes } from "react-router-dom";
import EpubPage from "../Pages/Epub";
import AudiosDetailPage from "../Pages/AudioPlayerPage";
import PageFooter from "./PageFooter";
import PageHeader from "./PageHeader";

const HomePage = React.lazy(
  () => import("../Pages/HomePage" /* webpackChunkName: "home" */)
);
const AboutPage: any = React.lazy(
  () => import("../Pages/AboutPage" /* webpackChunkName: "about" */)
);
const ArticlesPage = React.lazy(
  () => import("../Pages/ArticlesPage" /* webpackChunkName: "articles" */)
);
const AudiosPage = React.lazy(
  () => import("../Pages/AudiosPage" /* webpackChunkName: "audios" */)
);

// {Loading() ?
//   <div> {lyrt()} </div> : <Loading/>}

const BooksPage = React.lazy(
  () => import("../Pages/BooksPage" /* webpackChunkName: "books" */)
);
const PravachansPage = React.lazy(
  () => import("../Pages/PravachansPage" /* webpackChunkName: "pravachans" */)
);
const NotFoundPage = React.lazy(
  () => import("../Pages/NotFoundPage" /* webpackChunkName: "not-found" */)
);
const UserProfilePage = React.lazy(
  () => import("../Pages/UserProfilePage" /* webpackChunkName: "profile" */)
);

const BookDetailPage = React.lazy(
  () => import("../Pages/BookDetailPage" /* webpackChunkName: "book-details" */)
);
const ArticlesDetailPage = React.lazy(
  () =>
    import("../Pages/ArticlesDetail" /* webpackChunkName: "artical-details" */)
);
const MessagesDetailPage = React.lazy(
  () =>
    import(
      "../Pages/MessagesDetailPage" /* webpackChunkName: "message-details" */
    )
);
const MessagesPage = React.lazy(
  () => import("../Pages/MessagesPage" /* webpackChunkName: "messages" */)
);
// const EpubPage = React.lazy(() => import("../Pages/Epub"));
const ProfilePage = React.lazy(
  () => import("../Pages/ProfilePage" /* webpackChunkName: "profile" */)
);
const AuthorsPage = React.lazy(
  () => import("../Pages/AuthorsPage" /* webpackChunkName: "author" */)
);
const SearchDataPage = React.lazy(
  () => import("../Pages/SearchDataPage" /* webpackChunkName: "search" */)
);
const LoginDialogPage = React.lazy(
  () => import("../Pages/LoginDialog" /* webpackChunkName: "login" */)
);
const DivineQuotesPage = React.lazy(
  () =>
    import("../Pages/DivineQuotesPage" /* webpackChunkName: "devine-quote" */)
);
const PrivacyPolicyPage = React.lazy(
  () => import("../Pages/PrivacyPolicyPage" /* webpackChunkName: "ppp" */)
);
const TermsOfUsePage = React.lazy(
  () => import("../Pages/TermsOfUsePage" /* webpackChunkName: "toc" */)
);

const WithHeaderFooter = ({ element }: { element: any }) => {
  return (
    <>
      <div>
        <PageHeader />
        {element}
      </div>

      <PageFooter />
    </>
  );
};

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        element={<WithHeaderFooter element={<HomePage />} />}
        path="/"
      ></Route>
      {/* <Route element={<HomePage />} path='/home'></Route> */}
      <Route
        element={<WithHeaderFooter element={<AboutPage />} />}
        path="/about"
      ></Route>
      <Route element={<EpubPage />} path="/reader/books/"></Route>

      <Route element={<EpubPage />} path="/reader/:type:/:id"></Route>
      <Route element={<EpubPage />} path="/reader/books/:id"></Route>

      <Route
        element={<WithHeaderFooter element={<BooksPage />} />}
        path="/books"
      ></Route>
      <Route
        element={<WithHeaderFooter element={<BooksPage />} />}
        path="/books/special"
      ></Route>
      <Route
        element={<WithHeaderFooter element={<BookDetailPage />} />}
        path="/books/:id"
      ></Route>

      <Route
        element={<WithHeaderFooter element={<LoginDialogPage />} />}
        path="/LoginDialog"
      ></Route>

      <Route
        element={<WithHeaderFooter element={<PravachansPage />} />}
        path="/pravachans"
      ></Route>
      <Route
        element={<WithHeaderFooter element={<PravachansPage />} />}
        path="/pravachans/special"
      ></Route>
      <Route element={<AudiosDetailPage />} path="/pravachans/:id"></Route>
      <Route element={<AudiosDetailPage />} path="/pravachans/:cat/:id"></Route>

      <Route
        element={<WithHeaderFooter element={<AudiosPage />} />}
        path="/audios"
      ></Route>
      <Route
        element={<WithHeaderFooter element={<AudiosPage />} />}
        path="/audios/special"
      ></Route>

      <Route element={<AudiosDetailPage />} path="/audios/:id"></Route>
      <Route element={<AudiosDetailPage />} path="/audios/:cat/:id"></Route>

      <Route
        element={<WithHeaderFooter element={<ArticlesPage />} />}
        path="/articles"
      ></Route>
      <Route
        element={<WithHeaderFooter element={<ArticlesPage />} />}
        path="/articles/special"
      ></Route>
      <Route
        element={<WithHeaderFooter element={<ArticlesDetailPage />} />}
        path="/articles/:id"
      ></Route>

      <Route
        element={<WithHeaderFooter element={<AuthorsPage />} />}
        path="/author/:id"
      ></Route>

      <Route
        element={<WithHeaderFooter element={<UserProfilePage />} />}
        path="/profile"
      ></Route>

      <Route
        element={<WithHeaderFooter element={<DivineQuotesPage />} />}
        path="/divinequotes"
      ></Route>

      <Route
        element={<WithHeaderFooter element={<MessagesDetailPage />} />}
        path="/messages/:id"
      ></Route>
      <Route
        element={<WithHeaderFooter element={<MessagesPage />} />}
        path="/messages"
      ></Route>

      <Route
        element={<WithHeaderFooter element={<ProfilePage />} />}
        path="/ProfilePage"
      ></Route>
      <Route
        element={<WithHeaderFooter element={<SearchDataPage />} />}
        path="/searchdata/:searchCategory/:search"
      ></Route>

      <Route
        element={<WithHeaderFooter element={<NotFoundPage />} />}
        path="/*"
      ></Route>
      <Route
        element={<WithHeaderFooter element={<PrivacyPolicyPage />} />}
        path="/privacypolicy"
      ></Route>
      <Route
        element={<WithHeaderFooter element={<TermsOfUsePage />} />}
        path="/termsofuse"
      ></Route>
    </Routes>
  );
};
export default AppRoutes;
