/* eslint-disable react/jsx-no-target-blank */
import qrcode from "../Images/qrcodecode.png";
import { useTranslation } from "react-i18next";
import i18n, { _get_i18Lang } from "../i18n";
import React, { useEffect, useState } from "react";
import gitalogo from "../Images/transperentgeetalogo.png";
import appstore from "../Images/appstore.png";
import playstore from "../Images/playstore.png";
import $ from "jquery";
import { NavLink } from "react-router-dom";
import logofb from "../Images/facebook.svg";
import logoinsta from "../Images/instagram.svg";
import logotwitr from "../Images/twitter.svg";
import logoyoutube from "../Images/youtube.svg";
import { useUser } from "../Contexts/UserContext";

const PageFooter = () => {
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(false);
  const currentLanguage = _get_i18Lang();

  const [MenuId, setMenuId] = useState("");
  const [MenuName, setMenuName] = useState("");
  const [cardWidth, setcardWidth] = useState(window.innerWidth);

  window.addEventListener("resize", () => {
    setcardWidth(window.innerWidth);
  });

  function changeLocale(l: string) {
    i18n.changeLanguage(l);
    localStorage.setItem("lan", _get_i18Lang());
    localStorage.setItem("locale", l);
    //}
  }

  useEffect(() => {
    setRefresh(false);
    changeLocale(localStorage.getItem("locale") === "en" ? "en" : "hi");
  }, [refresh]);

  function menuid(menuname: string) {
    $(".header-menu > ul > li > a").removeClass("listActive");
    $("#menu_" + menuname).addClass("listActive");
    localStorage.setItem("MId", menuname);

    if (menuname === "about") {
      setMenuName(t("Introduction_tr"));
    } else if (menuname === "books") {
      setMenuName(t("E_books_tr"));
    } else if (menuname === "pravachans") {
      setMenuName(t("Pravachan_tr"));
    } else if (menuname === "audios") {
      setMenuName(t("Audios_tr"));
    } else if (menuname === "articles") {
      setMenuName(t("Article_tr"));
    } else if (menuname === "divinequotes") {
      setMenuName(t("Amrit_Vachan_tr"));
    }
  }

  useEffect(() => {
    setRefresh(false);
    changeLocale(localStorage.getItem("locale") === "en" ? "en" : "hi");
    localStorage.setItem("author_Id", "5bbc60101fd2d735b0087d36");
    $(".footer-menu > ul > li > a").removeClass("listActive");
    $("#menu_" + localStorage.getItem("MId")).addClass("listActive");
  }, []);

  useEffect(() => {
    menuid(MenuId);
  }, [MenuId]);

  return (
    <section className="footer" style={{ userSelect: "none", marginTop: '15px' }}>
      <div className="container">
        <div className="row" style={{justifyContent: cardWidth < 772 ? "center" : "none"}}>
          <div className={`${cardWidth < 772 ? "" : "col-lg-2 col-xs-6 col-md-6"}`}  
          style={{width: 'max-content'}}
          // className="col-lg-2 col-xs-6 col-md-6"
          >
          {/* {`${cardWidth<1000 && cardWidth>770 ? "col-lg-6 col-xs-6 col-md-12" :"col-lg-6 col-xs-6 col-md-6"}`} */}
            <div className="footer-menu">
              <ul className="list-unstyled p-0">
                <li>
                  <NavLink
                    id="menu_books"
                    to="/books"
                    style={({ isActive }) => {
                      return { color: isActive ? "#d11501" : "#472d1e" };
                    }}
                    onClick={() => {                  
                      setMenuId("books");
                      menuid("books");
                      setMenuName(t("E_books_tr"));
                    }}
                  >
                    {t("E_books_tr")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    id="menu_pravachans"
                    to="/pravachans"
                    style={({ isActive }) => {
                      return { color: isActive ? "#d11501" : "#472d1e" };
                    }}
                    onClick={() => {
                      localStorage.setItem("type", "pravachans");
                      setMenuId("pravachans");
                      menuid("pravachans");
                      setMenuName(t("Pravachan_tr"));
                    }}
                  >
                    {t("Pravachan_tr")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    id="menu_audios"
                    to="/audios"
                    style={({ isActive }) => {
                      return { color: isActive ? "#d11501" : "#472d1e" };
                    }}
                    onClick={() => {
                      localStorage.setItem("type", "audios");
                      setMenuId("audios");
                      menuid("audios");
                      setMenuName(t("Audios_tr"));
                    }}
                  >
                    {t("Audios_tr")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    id="menu_articles"
                    to="/articles"
                    style={({ isActive }) => {
                      return { color: isActive ? "#d11501" : "#472d1e" };
                    }}
                    onClick={() => {
                      setMenuId("articles");
                      menuid("articles");
                      setMenuName(t("Article_tr"));
                    }}
                  >
                    {t("Article_tr")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    id="menu_divinequotes"
                    to="/divinequotes"
                    style={({ isActive }) => {
                      return { color: isActive ? "#d11501" : "#472d1e" };
                    }}
                    onClick={() => {
                      setMenuId("divinequotes");
                      menuid("divinequotes");
                      setMenuName(t("Amrit_Vachan_tr"));
                    }}
                  >
                    {t("Amrit_Vachan_tr")}
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className={`${cardWidth < 772 ? "" : "col-lg-2 col-xs-6 col-md-6"}`}  
          style={{width: 'max-content'}}>
            <div className="footer-menu">
              <ul className="list-unstyled p-0">
                <li>
                  <NavLink
                    id="menu_about"
                    to="/about"
                    style={({ isActive }) => {
                      return { color: isActive ? "#d11501" : "#472d1e" };
                    }}
                    onClick={() => {
                      setMenuId("about");
                      menuid("about");
                      setMenuName(t("Introduction_tr"));
                    }}
                  >
                    {t("Introduction_tr")}
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/privacypolicy"
                    style={({ isActive }) => {
                      return { color: isActive ? "#d11501" : "#472d1e" };
                    }}
                  >
                    {t("Privacy_Policy_tr")}
                  </NavLink>
                </li>
              </ul>

              <ul className="list-unstyled p-0">
                <li>
                  <NavLink
                    to="/termsofuse"
                    style={({ isActive }) => {
                      return { color: isActive ? "#d11501" : "#472d1e" };
                    }}
                  >
                    {t("Terms_of_Use_tr")}
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className= {`${cardWidth<1000 && cardWidth>770 ? "col-lg-6 col-xs-6 col-md-12" :"col-lg-6 col-xs-6 col-md-6"}`} >
            <div className="download-apps">
              <div
                style={{
                  marginBottom: "15px",
                }}
              >
                <label style={{ color: "#ff9832", fontSize: "18px" }}>
                  {currentLanguage === "hindi" ? (
                    <label
                      style={{
                        lineHeight: "25px",
                        display: "inline-block",
                        marginBottom: "0.5rem",
                        fontFamily: "ChanakyaUni",
                        fontSize: "22px",
                      }}
                    >
                      श्री जयदयाल जी गोयंदका, श्री हनुमान प्रसाद जी पोद्दार तथा
                      स्वामी रामसुखदास जी महाराज की पुस्तकों, प्रवचनों, ऑडियो और
                      लेखों का एक दुर्लभ संग्रह <b>गीता सेवा</b> ऐप/वेबसाइट पर
                      उपलब्ध है।
                    </label>
                  ) : (
                    <label
                      style={{
                        lineHeight: "25px",
                        display: "inline-block",
                        marginBottom: "0.5rem",
                        fontFamily: "ChanakyaUni",
                        fontSize: "22px",
                      }}
                    >
                      A rare collection of Books, Discourses, Audios and
                      Articles by Shri Jaidayal Ji Goyandka, Shri Hanuman Prasad
                      Ji Poddar and Swami Ramsukhdas Ji Maharaj is available on{" "}
                      <b>Gita Seva</b> App/Website
                    </label>
                  )}
                </label>
              </div>
              <div style={{ textAlign: "center" }}>
                <div className="row btapps">
                  <div
                    className="col-lg-8 col-md-8 col-sm-8 col-xs-8"
                    style={{ marginBottom: "15px", display: "flex" }}
                  >
                    <a
                      href="https://gitaseva.org/"
                      target="_blank"
                      style={{
                        border: "1px solid orange",
                        borderRadius: "10px",
                        margin: "0 12px",
                        padding: "5px 6px",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        alt="logo"
                        src={gitalogo}
                        style={{
                          width: "120px",
                          height: "80px",
                          marginBottom: "20px",
                        }}
                      />
                      <label
                        style={{
                          backgroundColor: "#FEC442",
                          borderRadius: "5px",
                          padding: "0 12px",
                          cursor: "pointer",
                        }}
                      >
                        <label
                          style={{
                            fontSize: "12px",
                            color: "#000",
                            verticalAlign: "revert",
                            cursor: "pointer",
                          }}
                        >
                          Visit Website
                        </label>
                      </label>
                    </a>

                    <div
                      style={{
                        border: "1px solid orange",
                        borderRadius: "10px",
                        padding: "8px 10px",
                      }}
                    >
                      <img src={qrcode} alt="Swami Ramsukhdas Ji" />
                      <span
                        style={{
                          borderRadius: "5px",
                          marginBottom: "10px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "13px",
                            color: "#ff9832",
                            display: "inline-block",
                            padding: 0,
                          }}
                        >
                          Scan to install app
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <div className="download">
                      <a
                        href="https://play.google.com/store/apps/details?id=ct.android.gitasevakotlin"
                        target="_blank"
                      >
                        <img
                          style={{ width: "190px"}}
                          src={playstore}
                          alt="iOS Apple Store"
                        />
                      </a>

                      <a
                        href="https://itunes.apple.com/us/app/gita-seva-app/id1418594830"
                        target="_blank"
                      >
                        <img
                          style={{ width: "190px", marginTop: `${cardWidth > 576 ? "10px" : "" }` }}
                          src={appstore}
                          alt="Google Play Sotre"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${cardWidth<1250 && cardWidth>950? "" :cardWidth<949 && cardWidth>550? "col-lg-2 col-xs-6 col-md-12 icons":"col-lg-2 col-xs-6 col-md-6 icons"}`}>
            <div className="social-icons" style={{float: 'right'}}>
              <h5 className="socialheader">Follow us on!</h5>

              <ul
                className="list-unstyled p-0 footersocial"
                style={{
                  display: `${cardWidth < 550 ? "grid" :cardWidth<1250 && cardWidth>550?"flex":"block" }`,
                  gridTemplateColumns: "auto auto",
                  justifyContent:"space-around",               
                  
                }}
              >
                <li className="fb">
                  <a
                    style={{ color: "#3D2B31" }}
                    href="https://www.facebook.com/gitasevaapp"
                    target="_blank"
                  >
                    {t("Facebook_tr")}
                  </a>
                </li>

                <li className="twit">
                  <a
                    style={{ color: "#3D2B31" }}
                    href="https://twitter.com/gitasevaapp"
                    target="_blank"
                  >
                    {t("Twitter_tr")}
                  </a>
                </li>

                <li className="insta">
                  <a
                    style={{ color: "#3D2B31" }}
                    href="https://www.instagram.com/gitasevaapp"
                    target="_blank"
                  >
                    {t("Instagram_tr")}
                  </a>
                </li>

                <li className="utube">
                  <a
                    style={{ color: "#3D2B31" }}
                    href="https://www.youtube.com/c/GitaSevaApp"
                    target="_blank"
                  >
                    {t("YouTube_tr")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="border-gradient"></div>

        <div className="copyright text-center">
          <p className="copyrgt">
            © Copyright 2023 |
            <p className="orangetxt">Swami Ramsukhdas Ji Maharaj</p>| All Rights
            Reserved
          </p>
          <p className="powerby">
            Powered By:
            <a
              href="https://gitaseva.org/"
              target="_blank"
              className="orangetxt"
            >
              Gita Seva Trust
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};
export default PageFooter;
