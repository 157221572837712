import React, { useState } from "react";
import { useAudio } from "../Contexts/AudiosContext";
import RabbitLyrics from "rabbit-lyrics";
import "../Styles/AudioPlayer.css";
import lyricsnotfound from "../Images/lyricsnotfound.png";
import Loading from "./Loading";
import menu from "../Images/menu.png";

const AudioLyrics = () => {
  const { currentAudio, lyrt, showList, setShowList } = useAudio();
  const refLrc = React.useRef<any>(null);

  const [cardWidth, setcardWidth] = useState(window.innerWidth);

  window.addEventListener("resize", () => {
    setcardWidth(window.innerWidth);
  });

  React.useEffect(() => {
    if (currentAudio) {
      if (refLrc.current) {
        new RabbitLyrics(
          refLrc.current,
          document.getElementById(`audio-${currentAudio?.id}`) as any
        );
      }
    }
  }, [currentAudio, currentAudio?.lyricsHash, lyrt]);

  return (
    <div style={{ marginTop: "-70px" }}>
      <div className="header">
        {cardWidth < 769 ? (
          <>
            <span>
              <img
                alt="plus"
                style={{ width: "25px", float: "left", margin: "12px 0 0 3%" }}
                src={menu}
                onClick={() => {
                  setShowList((x:boolean)=> !x)
                }}
              />
            </span>
            <span style={{ fontSize: 20, color: "#ff9c00", fontFamily: 'ChanakyaUni' }}>
              {currentAudio?.name != null && currentAudio.name.length > 15
                ? currentAudio.name.slice(0, 15)
                : currentAudio?.name}
            </span>
          </>
        ) : (
          <span style={{ fontSize: 30, color: "#ff9c00", fontFamily: 'ChanakyaUni' }}>
            {currentAudio?.name != null && currentAudio.name.length > 50
              ? currentAudio.name.slice(0, 42)
              : currentAudio?.name}
          </span>
        )}
            </div>
      <div>
        {currentAudio?.lyricsHash != null ? (
          <div className="lyrics-data" style={{ height: "100%" }}>
            <div
              ref={refLrc}
              className="lyrics"
              style={{
                fontSize: 20,
                padding: "10px",
                fontFamily: "ChanakyaUniBold",
              }}
            >
              {Loading() ? <div> {lyrt()} </div> : <Loading />}
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "88%",
              float: "left",
              textAlign: "center",
              marginLeft: "8%",
            }}
          >
            <img
              alt="lyricsnotfound"
              src={lyricsnotfound}
              className="lyricsnotfoundstyle"
            ></img>
          </div>
        )}
      </div> 
    </div>
  );
};
export default AudioLyrics;
