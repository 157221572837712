import "../Styles/style.css";
import search from "../Images/search-btn.svg";
import { useTranslation } from "react-i18next";
import { NavDropdown } from "react-bootstrap";
import i18n, { _get_i18Lang } from "../i18n";
import React, { useEffect, useRef, useState } from "react";
import { ISearchParams } from "../Services/SearchData";
import { Link, useNavigate, NavLink } from "react-router-dom";
import $ from "jquery";
import HeaderService from "../Services/PageHeader";
import Hindilogo from "../Images/hindi.svg";
import Englishlogo from "../Images/english.svg";
import { useUser } from "../Contexts/UserContext";
import menuicon from "../Images/menu-icon.png";

const PageHeader = (props: any) => {
  const { isSelected, setIsSelected } = useUser();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<ISearchParams>({
    language: _get_i18Lang(),
    productType: "all",
    searchValue: "",
  });
  const [refresh, setRefresh] = useState(false);
  const [MenuId, setMenuId] = useState("");
  const [MenuName, setMenuName] = useState("");
  const [reloadPage, SetReloadPage] = useState(false);
  const [cardWidth, setcardWidth] = useState(window.innerWidth);
  window.addEventListener("resize", () => {
    setcardWidth(window.innerWidth);
  });

  const [isOpened, setIsOpened] = useState(false);

  function toggle() {
    setIsOpened((wasOpened) => !wasOpened);
  }
  const [color, setColor] = React.useState<any>(
    localStorage.getItem("hf-color") === "true"
  );

  React.useEffect(() => {
    return localStorage.setItem("dark-mode", color);
  }, [color]);
  console.log("color", color);
  const togglemenuColor = () => {
    debugger;
    setColor(!color);
  };

  function changeLocale(l: string) {
    i18n.changeLanguage(l);
    localStorage.setItem("lan", _get_i18Lang());
    localStorage.setItem("locale", l);
    if (localStorage.getItem("locale") === "en") {
      $(".select-lang").removeClass("langwidth");
    } else {
      $(".select-lang").addClass("langwidth");
    }
  }

  function menuid(menuname: string) {
    $(".header-menu > ul > li > a").removeClass("listActive");
    $("#menu_" + menuname).addClass("listActive");

    $(".header-menu > btntogl > bg-light > me-auto > a").removeClass(
      "listActive"
    );
    $("#menu_" + menuname).addClass("listActive");
    localStorage.setItem("MId", menuname);

    if (menuname === "about") {
      setMenuName(t("Introduction_tr"));
    } else if (menuname === "books") {
      setMenuName(t("E_books_tr"));
    } else if (menuname === "pravachans") {
      setMenuName(t("Pravachan_tr"));
    } else if (menuname === "audios") {
      setMenuName(t("Audios_tr"));
    } else if (menuname === "articles") {
      setMenuName(t("Article_tr"));
    } else if (menuname === "divinequotes") {
      setMenuName(t("Amrit_Vachan_tr"));
    }
  }

  const ref = useRef<any>();

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (isOpened && ref.current && !ref?.current?.contains(e?.target)) {
        setIsOpened(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpened]);

  useEffect(() => {
    setRefresh(false);
    changeLocale(localStorage.getItem("locale") === "en" ? "en" : "hi");
    localStorage.setItem("author_Id", "5bbc60101fd2d735b0087d36");
    $(".header-menu > ul > li > a").removeClass("listActive");
    $("#menu_" + localStorage.getItem("MId")).addClass("listActive");
    $(".header-menu > btntogl > bg-light > me-auto > a").removeClass(
      "listActive"
    );
    $("#menu_" + localStorage.getItem("MId")).addClass("listActive");
    if (localStorage.getItem("locale") === "en") {
      $(".select-lang").removeClass("langwidth");
    } else {
      $(".select-lang").addClass("langwidth");
    }
  }, []);

  useEffect(() => {
    HeaderService.getUserLogin(
      "+91",
      "",
      "gitasevatrust1923@gmail.com",
      2,
      "Gita Seva",
      "",
      ""
    ).then((res) => {
      if (res) {
        localStorage.setItem("Token", res.result.token);
        localStorage.setItem("SignKey", res.result.signKey);
        localStorage.setItem("UserId", res.result.userId);
      }
    });
  }, [localStorage.getItem("SignKey"), reloadPage]);

  useEffect(() => {
    menuid(MenuId);
  }, [i18n.language, reloadPage]);

  const currentLanguage = _get_i18Lang();

  return (
    <section className="main-header" style={{ userSelect: "none" }}>
      <div className="container">
        <div className="clearfix position-relative">
          <span className="s-hari ff-VerdanaBold">{t("shrihari_tr")}</span>
          <div className="header-logo">
            <Link
              to={"/"}               
              onClick={() => {
                setMenuId("home");
                menuid("home");
                setMenuName("");
              }}
            >
              <img
                style={{ width: cardWidth < 450 ? "300px" : "350px" }}
                src={currentLanguage === "hindi" ? Hindilogo : Englishlogo}
                alt="Swamilogo"
              />
            </Link>
          </div>

          <div
            className="search-box"
            style={{
              margin:
                cardWidth > 500 && cardWidth < 575 ? "10px 15px 0 15px" : "",
            }}
          >
            <div className="search-items ">
              <form className="m-0">
                <div className="form-group position-relative">
                  <input
                    type="search"
                    name="search"
                    className="search-field"
                    value={searchValue.searchValue}
                    onChange={(e) => {
                      setSearchValue({
                        ...searchValue,
                        searchValue: e.target.value,
                      });
                    }}
                    onKeyDown={(e: any) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        navigate(
                          `/searchdata/${searchValue.productType}/${searchValue.searchValue}`,
                          { state: searchValue }
                        );
                      }
                    }}
                  />

                  <img
                    style={{ width: "28px", paddingTop: "3px" }}
                    src={search}
                    alt="search"
                    onClick={() => {
                      navigate(
                        `/searchdata/${searchValue.productType}/${searchValue.searchValue}`,
                        { state: searchValue }
                      );
                    }}
                  />
                </div>
              </form>
            </div>
            <div className="select-lang" id="selectlang">
              <NavDropdown
                title={
                  localStorage.getItem("locale") === "hi" ? "हिंदी" : "English"
                }
                id="language-nav-dropdown"
                style={{ width: NavDropdown.Item.name, textAlign: "left" }}
              >
                <NavDropdown.Item
                  active={localStorage.getItem("locale") === "hi"}
                  onClick={() => {
                    changeLocale("hi");
                    setSearchValue({
                      ...searchValue,
                      language: "हिंदी",
                    });
                  }}
                >
                  हिंदी
                </NavDropdown.Item>
                <NavDropdown.Item
                  active={localStorage.getItem("locale") === "en"}
                  onClick={() => {
                    changeLocale("en");
                    setSearchValue({
                      ...searchValue,
                      language: "english",
                    });
                  }}
                >
                  English
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>
        </div>

        {cardWidth < 767 ? (
          <div className="menuicon">
            <div ref={ref}>
              <span
                className="link-btn"
                style={{
                  cursor: "pointer",
                  marginTop: "14px",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <img
                  id="userimg"
                  src={menuicon}
                  title="User Login"
                  style={{
                    width: "30px",
                    height: "25px",
                    margin: "0 15px 0 0",
                  }}
                  alt="user"
                  onClick={toggle}
                />
              </span>
              {isOpened && (
                <div
                  style={{
                    display: "grid",
                    textAlign: "center",
                    zIndex: 3,
                    position: "absolute",
                    backgroundColor: "blanchedalmond",
                    padding: "15px 20px",
                    left: "64%",
                    width: "170px",
                    borderRadius: "5px",
                    marginTop: "5px",
                  }}
                >
                  <NavLink
                    id="menu_home"
                    to="/"
                    className="headermenulink"
                    style={({ isActive }) => {
                      return { color: isActive ? "#d11501" : "#472d1e" };
                    }}
                    onClick={() => {
                      setIsOpened(false);
                    }}
                  >
                    {t("Home_tr")}
                  </NavLink>
                  <NavLink
                    id="menu_about"
                    to="/about"
                    className="headermenulink"
                    style={({ isActive }) => {
                      return { color: isActive ? "#d11501" : "#472d1e" };
                    }}
                    onClick={() => {
                      setIsOpened(false);
                    }}
                  >
                    {t("Introduction_tr")}
                  </NavLink>
                  <NavLink
                    id="menu_books"
                    to="/books"
                    className="headermenulink"
                    style={({ isActive }) => {
                      return { color: isActive ? "#d11501" : "#472d1e" };
                    }}
                    onClick={() => {
                      setIsSelected((x: boolean) => !x);
                      setIsOpened(false);
                    }}
                  >
                    {t("E_books_tr")}
                  </NavLink>
                  <NavLink
                    id="menu_pravachans"
                    to="/pravachans"
                    className="headermenulink"
                    style={({ isActive }) => {
                      return { color: isActive ? "#d11501" : "#472d1e" };
                    }}
                    onClick={() => {
                      setIsSelected((x: boolean) => !x);
                      localStorage.setItem("type", "pravachans");
                      setIsOpened(false);
                    }}
                  >
                    {t("Pravachan_tr")}
                  </NavLink>
                  <NavLink
                    id="menu_audios"
                    to="/audios"
                    className="headermenulink"
                    style={({ isActive }) => {
                      return { color: isActive ? "#d11501" : "#472d1e" };
                    }}
                    onClick={() => {
                      setIsSelected((x: boolean) => !x);
                      localStorage.setItem("type", "audios");
                      setIsOpened(false);
                    }}
                  >
                    {t("Audios_tr")}
                  </NavLink>
                  <NavLink
                    id="menu_articles"
                    to="/articles"
                    className="headermenulink"
                    style={({ isActive }) => {
                      return { color: isActive ? "#d11501" : "#472d1e" };
                    }}
                    onClick={() => {
                      setIsOpened(false);
                    }}
                  >
                    {t("Article_tr")}
                  </NavLink>
                  <NavLink
                    id="menu_divinequotes"
                    to="/divinequotes"
                    className="headermenulink"
                    style={({ isActive }) => {
                      return { color: isActive ? "#d11501" : "#472d1e" };
                    }}
                    onClick={() => {
                      setIsOpened(false);
                    }}
                  >
                    {t("Amrit_Vachan_tr")}
                  </NavLink>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="header-menu">
            <ul className="list-unstyled">
              <li>
                <NavLink
                  id="menu_home"
                  to="/"
                  // className={splitLocation[1] === "" ? "listActive" : "#3D2B31"}
                  style={({ isActive }) => {
                    return { color: isActive ? "#d11501" : "#472d1e" };
                  }}
                  onClick={() => {
                    setMenuId("home");
                    menuid("home");
                    setMenuName("");
                  }}
                >
                  {t("Home_tr")}
                </NavLink>
              </li>

              <li>
                <NavLink
                  id="menu_about"
                  to="/about"
                  style={({ isActive }) => {
                    return { color: isActive ? "#d11501" : "#472d1e" };
                  }}
                  onClick={() => {
                    setMenuId("about");
                    menuid("about");
                    setMenuName(t("Introduction_tr"));
                  }}
                >
                  {t("Introduction_tr")}
                </NavLink>
              </li>

              <li>
                <NavLink
                  id="menu_books"
                  to="/books"
                  style={({ isActive }) => {
                    return { color: isActive ? "#d11501" : "#472d1e" };
                  }}
                  onClick={() => {
                    setIsSelected((x: boolean) => !x);
                    setMenuId("books");
                    menuid("books");
                    setMenuName(t("E_books_tr"));
                  }}
                >
                  {t("E_books_tr")}
                </NavLink>
              </li>

              <li>
                <NavLink
                  id="menu_pravachans"
                  to="/pravachans"
                  style={({ isActive }) => {
                    return { color: isActive ? "#d11501" : "#472d1e" };
                  }}
                  onClick={() => {
                    setIsSelected((x: boolean) => !x);
                    localStorage.setItem("type", "pravachans");
                    setMenuId("pravachans");
                    menuid("pravachans");
                    setMenuName(t("Pravachan_tr"));
                  }}
                >
                  {t("Pravachan_tr")}
                </NavLink>
              </li>

              <li>
                <NavLink
                  id="menu_audios"
                  to="/audios"
                  style={({ isActive }) => {
                    return { color: isActive ? "#d11501" : "#472d1e" };
                  }}
                  onClick={() => {
                    setIsSelected((x: boolean) => !x);
                    localStorage.setItem("type", "audios");
                    setMenuId("audios");
                    menuid("audios");
                    setMenuName(t("Audios_tr"));
                  }}
                >
                  {t("Audios_tr")}
                </NavLink>
              </li>

              <li>
                <NavLink
                  id="menu_articles"
                  to="/articles"
                  style={({ isActive }) => {
                    return { color: isActive ? "#d11501" : "#472d1e" };
                  }}
                  onClick={() => {
                    setMenuId("articles");
                    menuid("articles");
                    setMenuName(t("Article_tr"));
                  }}
                >
                  {t("Article_tr")}
                </NavLink>
              </li>

              <li>
                <NavLink
                  id="menu_divinequotes"
                  to="/divinequotes"
                  style={({ isActive }) => {
                    return { color: isActive ? "#d11501" : "#472d1e" };
                  }}
                  onClick={() => {
                    setMenuId("divinequotes");
                    menuid("divinequotes");
                    setMenuName(t("Amrit_Vachan_tr"));
                  }}
                >
                  {t("Amrit_Vachan_tr")}
                </NavLink>
              </li>
            </ul>
          </div>
        )}
      </div>
    </section>
  );
};
export default PageHeader;
